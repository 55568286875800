import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent10() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    }
  }
  return (
    <>
      <div className="project-details-content">
        <div className="project-thumb">
          <img decoding="async" src={process.env.PUBLIC_URL + '/img/project/thesquatbabe.png'} alt="The Squat Babe Logo" loading="lazy" title="The Squat Babe" />
          <div className="tag">
            <a href="https://thesquatbabe.ro/">Website The Squat Babe</a>
          </div>
        </div>
        <h3>The Squat Babe</h3>
        <p>Colaborarea cu Alin a început dintr-o pasiune comună referitoare la modul în care literele pot spune povești. Ulterior, am discutat despre nevoile sale și obiectivele pe care acesta le are prin implementarea unui website. </p>
        <p>După ce am stablit publicul țintă, am început lucrul la detalii cum ar fi conținutul de pe site, paleta de culori și tipul de conținut. Având ca cerință lucrul în limba Germană, au fost create articole și texte care să atragă clienții doriți de Alin, rezultatele fiind vizibile în primele luni. </p>

        <div className="clinet-need">
          <h4>Respectarea dorințelor clientului:</h4>
          <p>Structura Websiteului a fost de asemenea analizată în detaliu, întrucât cerințele lui Alin au fost clare, orientate spre publicul țintă.</p>
          <p>În prezent, asigurăm servicii de mententanță pentru websiteul BC VOLUMETRICH, mulțumindu-i în același timp, pe această cale, lui Cosmin pentru toată încrederea acordată. </p>
        </div>
      </div>

    </>
  )
}

export default ProjectDetailsContent10