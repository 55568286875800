// Importă SDK-urile Firebase necesare
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Configurația Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCJOh8T6vz1lCY052DVzDq27Xv1Ddy7jZM",
  authDomain: "brandy-app-7d38c.firebaseapp.com",
  projectId: "brandy-app-7d38c",
  storageBucket: "brandy-app-7d38c.appspot.com", // 🔹 Ai greșit aici! Corect este "appspot.com"
  messagingSenderId: "954886990511",
  appId: "1:954886990511:web:f7372089e4607dc0c5025a",
  measurementId: "G-ZSQ4VKRXKJ"
};

// Inițializează Firebase (evită reinițializarea)
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const firestoreDB = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);

// Exportă modulele necesare
export { firebaseApp, firestoreDB, firebaseAuth, analytics };

// export const analytics = getAnalytics(app);
// remoteConfig.settings = {
//     minimumFetchIntervalMillis: process.env.REACT_APP_LA_ENVIRONMENT === 'production' ? 3600000 : process.env.REACT_APP_LA_ENVIRONMENT === 'preview' ? 60000 : 10000,
//     fetchTimeoutMillis: 60000,
// };

// export const firebaseCloudMessaging = {
//     tokenInlocalforage: async () => {
//         const token = await localforage.getItem("fcm_token");
//         console.log("fcm_token tokenInlocalforage", token);
//         return token;
//     },
//     onMessage: async () => {
//         const messaging = getMessaging();
//         onMessage(messaging, (payload) => {
//             console.log("Message received. ", payload);
//             alert("Notificacion");
//         });
//     },

//     init: async function () {
//         try {
//             if ((await this.tokenInlocalforage()) !== null) {
//                 console.log("it already exists");
//                 return false;
//             }
//             console.log("it is creating it.");
//             const messaging = getMessaging(app);
//             await Notification.requestPermission();
//             getToken(messaging, {
//                 vapidKey:
//                     "MyvapidKeyFromFirebase Look for the documentation how to generate this token in Firebase. it is quite simple",
//             })
//                 .then((currentToken) => {
//                     console.log("current Token", currentToken);
//                     if (currentToken) {
//                         // Send the token to your server and update the UI if necessary
//                         // save the token in your database
//                         localforage.setItem("fcm_token", currentToken);
//                         console.log("fcm_token", currentToken);
//                     } else {
//                         // Show permission request UI
//                         console.log(
//                             "NOTIFICACION, No registration token available. Request permission to generate one."
//                         );
//                         // ...
//                     }
//                 })
//                 .catch((err) => {
//                     console.log(
//                         "NOTIFICACIONAn error occurred while retrieving token . "
//                     );
//                     console.log(err);
//                 });
//         } catch (error) {
//             console.error(error);
//         }
//     },
// };